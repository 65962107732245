import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import navigatorResourceListStyle from '../styles/navigatorResourceList.scss';
import Scroll from '../components/Scroll';
import ModalItem from '../components/Modal';
import { truncateWord } from "../utils";
import AnalyticsService from 'analytics-web';
import { useIntl } from "gatsby-plugin-intl"

const NavigatorResourceList = ({ data, style, siteMetaData, analyticsId }) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  if (!data)
    return "";
  const {
    siteMetadata: {
      analytics_config_alias = '',
      analytics_config_accounts = ''
    } = {}
  } = siteMetaData;
  if (analytics_config_alias) {
    AnalyticsService.initialize(
      analytics_config_alias,
      {
        accounts: analytics_config_accounts
      },
      {
        disableAjaxTracking: true
      }
    );
  }
  const handleAnalyticsTrack = (type, action) => {
    const id = `${analyticsId}_${(action.title).replace(/ /g, "-")}`;
    const date = new Date();
    if (analytics_config_alias) {
      AnalyticsService.track(type, {
        context: formatMessage({ id: 'analyticsContext' }),
        page: formatMessage({ id: 'analyticsPage' }),
        timeClicked: date,
        actions: id,
        url: window.location.hostname + window.location.pathname
      })
    }
  }

  return (
    <>
      {data.map((resource, index) => (
        <div className='resource-section' css={[css(navigatorResourceListStyle), css(style)]} key={index}>
          {resource.resourceSectionTitle && <h2>{resource.resourceSectionTitle}</h2>}
          {
            resource.scrollbar ? (
              <ul className='mobile-button-group scrolling'>
                <Scroll>
                  {
                    resource.resourceList.map((resourceItem, i) => (
                      <li key={i}
                        className={'mobile-button ' + resourceItem.navigatorButtonType.replace(/ /g, "-") + ' scroll-box'}
                        onClick={(e) => { handleAnalyticsTrack('tile_click_event', resourceItem) }}
                      >
                        <a
                          href={(resourceItem.linkType === 'External Link' ? resourceItem.buttonUrl : (resourceItem.buttonPage && resourceItem.buttonPage.slug))}
                        >
                          {resourceItem.navigatoricon &&
                            <img src={resourceItem.navigatoricon.sourceUrl} alt="icon" />
                          }
                          <div>
                            <span>{truncateWord(resourceItem.title)}</span>
                          </div>
                        </a>
                      </li>
                    ))
                  }
                </Scroll>
              </ul>
            ) : (
              <ul className='mobile-button-group'>
                {
                  resource.resourceList.map((resourceItem, i) => (
                    <li key={i}
                      className={'mobile-button ' + resourceItem.navigatorButtonType.replace(/ /g, "-")}
                      onClick={(e) => { handleAnalyticsTrack('tile_click_event', resourceItem) }}
                    >
                      {
                        resourceItem.buttonMultipleExternalLinksPage && resourceItem.buttonMultipleExternalLinksPage.length > 0 ? (
                          <>
                            <button onClick={() => setIsOpen(true)}>{resourceItem.title}</button>
                            <ModalItem isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
                              <ul className='mobile-button-group'>
                                {
                                  resourceItem.buttonMultipleExternalLinksPage.map(buttonLink => (
                                    buttonLink.link && (
                                      <li key={i}
                                        className={'mobile-button ' + resourceItem.navigatorButtonType.replace(/ /g, "-")}
                                      >
                                        <a href={buttonLink.link}>
                                          <span>{buttonLink.linkLabel}</span>
                                        </a>
                                      </li>
                                    )
                                  ))
                                }
                              </ul>
                            </ModalItem>
                          </>
                        ) : (
                          <a
                            href={(resourceItem.linkType === 'External Link' ? resourceItem.buttonUrl : (resourceItem.buttonPage && resourceItem.buttonPage.slug))}
                          >
                            {resourceItem.navigatoricon &&
                              <img src={resourceItem.navigatoricon.sourceUrl} alt="icon" />
                            }
                            <span>{truncateWord(resourceItem.title)}</span>
                          </a>
                        )
                      }
                    </li>
                  ))
                }
              </ul>
            )
          }
        </div>
      ))
      }
    </>
  )
}

const NavigatorResourceListStyled = (props) => (
  <NavigatorResourceList {...props} style={props.style} />
);

NavigatorResourceList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  style: PropTypes.string
};

NavigatorResourceList.defaultProps = {
  data: [],
  style: ''
};

NavigatorResourceListStyled.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  style: PropTypes.string
};

NavigatorResourceListStyled.defaultProps = {
  data: [],
  style: ''
};

export default NavigatorResourceListStyled;
