import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const NavigatorLayout = ({ children, style }) => {
    return (
        <div className='navigator-layout'
            css={css(style)}
        >
            {children}
        </div>
    )
};

const NavigatorLayoutStyled = (props) => (
    <NavigatorLayout {...props} style={props.style} />
);

NavigatorLayout.propTypes = {
    children: PropTypes.node,
    style: PropTypes.string
};

NavigatorLayout.defaultProps = {
    children: null,
    style: ''
};

NavigatorLayoutStyled.propTypes = {
    children: PropTypes.node,
    style: PropTypes.string
};

NavigatorLayoutStyled.defaultProps = {
    children: null,
    style: ''
};

export default NavigatorLayout;
