import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import NavigatorLayout from '../components/NavigatorLayout';
import NavigatorResourceList from '../components/NavigatorResourceList'
import navigatorSubSectionStyle from '../styles/navigatorSubSection.scss';
import PropTypes from 'prop-types';

const MobileNavigatorSubPage = ({ data }) => {
  const service = data.allWpService.nodes[0];
  let navigatorLayoutStyle = `
    padding: 10px 0 10px 10px;
    `;

  return (
    <NavigatorLayout style={navigatorLayoutStyle}>
      <div className='navigator-mobile-sub-section-container' css={[css(navigatorSubSectionStyle)]}>
        <div dangerouslySetInnerHTML={{ __html: service.content }} />
        <NavigatorResourceList data={service.servicesFields.resourceSection} siteMetaData={data.site} analyticsId={service.slug} />
      </div>
    </NavigatorLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        analytics_config_alias,
        platform,
        analytics_config_accounts
      }
    }
    allWpService(filter: {slug: {eq: $slug}}) {
      nodes {
        id
        content
        title
        slug
        uri
        servicesFields {
          fieldGroupName
          headerBackgroundImage {
            altText
            title
            sourceUrl
          }
          listingButtonLabel
          resourceSection {
            fieldGroupName
            scrollbar
            resourceSectionTitle
            resourceList {
              icon {
                sourceUrl
                altText
              }
              navigatoricon {
                sourceUrl
                altText
              }
              title
              buttonText
              buttonUrl
              fieldGroupName
              text
              navigatorButtonType
              linkType
              buttonPage { 
                ... on WpService {
                  slug
                }
              }
              buttonMultipleExternalLinksPage {
                fieldGroupName
                link
                linkLabel
              }
            }
          }
          listingButtonImage {
            altText
            title
            sourceUrl
          }
        }
        ancestors {
          nodes {
            ... on WpService {
              title
            }
          }
        }
      }
    }
  }
`;

const MobileNavigatorSubPageStyled = (props) => (
  <MobileNavigatorSubPage {...props} />
);

MobileNavigatorSubPage.propTypes = {
  data: PropTypes.shape({}),
};

MobileNavigatorSubPage.defaultProps = {
  data: {},
};

MobileNavigatorSubPageStyled.propTypes = {
  data: PropTypes.shape({})
};

MobileNavigatorSubPageStyled.defaultProps = {
  data: {}
};

export default (MobileNavigatorSubPageStyled);
